import React from "react";
import { RouteObject } from "react-router";

import BaseLayout from "../pages/base/BaseLayout";
import DashboardPage from "../pages/base/DashboardPage";
import PropertiesPage from "../pages/base/PropertiesPage";
import MessagesPage from "../pages/base/MessagesPage";
import SupportPage from "../pages/base/SupportPage";
import AgentsPage from "../pages/base/AgentsPage";
import UsersPage from "../pages/base/UsersPage";
import TransactionsPage from "../pages/base/TransactionsPage";
import SettingsPage from "../pages/base/SettingsPage";
import PropertyPage from "../pages/base/PropertyPage";
import PropertyCreatePage from "../pages/base/PropertyCreatePage";
import PropertyEditPage from "../pages/base/PropertyEditPage";
import NotFoundPage from "../pages/NotFoundPage";

const routesAdmin: RouteObject[] = [
  {
    element: <BaseLayout/>,
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/properties",
        element: <PropertiesPage/>,
      },
      {
        path: "/property/:id",
        element: <PropertyPage/>,
      },
      {
        path: "/property/create",
        element: <PropertyCreatePage/>,
      },
      {
        path: "/property/:id/edit",
        element: <PropertyEditPage/>,
      },
      {
        path: "/messages",
        element: <MessagesPage/>,
      },
      {
        path: "/agents",
        element: <AgentsPage/>,
      },
      {
        path: "/users",
        element: <UsersPage/>,
      },
      {
        path: "/transactions",
        element: <TransactionsPage/>,
      },
      {
        path: "/support",
        element: <SupportPage/>,
      },
      {
        path: "/settings",
        element: <SettingsPage/>,
      },
      {
        path: "*",
        element: <NotFoundPage/>
      },
    ]
  },
]

export default routesAdmin;
