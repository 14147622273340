import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import { getUsersList } from "../../api/services/user/requests";

import { IUser } from "../../common/types/dto/user";

import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import Input from "../../common/components/Input/Input";
import Pagination from "../../common/components/Pagination/Pagination";

import UserProfile from "../../features/users/components/UserProfile";

import MagnifyingGlass from "../../common/assets/icons/MagnifyingGlass";
import ArrowBackIcon from "../../common/assets/icons/ArrowBackIcon";

const USERS_PER_PAGE = 20;

const AgentsPage = () => {
  const [page, setPage] = useState(1);
  const [user, setUser] = useState<IUser | null>(null);

  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { control, watch } = useForm<{
    search: string,
  }>({
    defaultValues: {
      search: "",
    }
  });

  const search = watch("search");

  const { data } = useQuery(["usersList", search.length > 2 ? search : '', page], async () => {
    const payload = {
      role: 'client',
      ...search.length > 2 && { email: search },
      page,
      limit: USERS_PER_PAGE,
    };

    setLoader(true);
    const data = await getUsersList(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    },
  });

  const handleShowProfile = (user: IUser) => {
    setUser(user);
  }

  const handleBack = () => {
    setUser(null);
  }

  const usersMap = data?.items?.map((user: IUser) => {
    const { id, name, email, avatar, createdAt, isBlocked } = user
    return (
      <React.Fragment key={id}>
        <div className="flex items-center gap-[10px]">
          <div className="h-[36px] w-[36px]">
            {avatar?.url ? (
              <img
                src={avatar.url}
                width={36}
                height={36}
                alt="user icon"
                className="h-[36px] w-[36px] rounded-[12px] object-cover"
              />
            ) : (
              <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{name?.charAt(0)}</div>
            )}
          </div>
          <div className="leading-[1.2] text-[14px]">
            <div className="text-[#2D3748] font-bold">{name}</div>
            <div className="text-[#718096]">{email}</div>
          </div>
        </div>
        <div className="text-center text-[14px] text-[#E53E3E] font-bold">{isBlocked && t('blocked')}</div>
        <div className="text-center text-[14px] text-[#2D3748] font-bold">{
          new Intl.DateTimeFormat('default', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          }).format(new Date(createdAt)).replace(/\./g, '/')
        }</div>
        <div>
          <button onClick={() => handleShowProfile(user)}
                  className="text-[12px] text-[#006CFB] font-bold whitespace-nowrap">{t('show_profile')}</button>
        </div>
        <div className="col-span-4 border-t border-[#E2E8F0]"/>
      </React.Fragment>
    )
  })

  const totalPages = !!data?.total && data.total > data.limit && Math.ceil(data.total / USERS_PER_PAGE);

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <div className="flex items-center justify-between gap-[20px]">
        {user ? (
          <div className="relative flex-center w-[38px] h-[38px] bg-white rounded-[10px] cursor-pointer" onClick={handleBack}>
            <ArrowBackIcon color="black"/>
          </div>
        ) : <div />}
        {!user ? (
          <div className="relative w-[317px]">
            <Input
              control={control}
              type="text"
              name="search"
              classnames="flex items-center w-full h-[38px] pl-[50px] pr-[10px] rounded-[8px] bg-[#F4F4F4]"
              placeholder="Search..."
              unstyled
            />
            <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2"/>
          </div>
        ) : <div />}
      </div>
      <div className="mt-[25px]">
        {user ? (
          <UserProfile user={user} />
        ) : (
          <CardWrapper className="p-[30px]">
            <div className="text-[#11142d] text-[18px] font-semibold mb-3">{t('users')}</div>
            <div className="grid grid-cols-[1fr_130px_130px_80px] gap-x-[50px] gap-y-3 items-center mb-6">
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold">{t('user')}</div>
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('status')}</div>
              <div
                className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('date_of_registration')}</div>
              <div/>
              <div className="col-span-4 border-t border-[transparent]"/>
              {usersMap}
            </div>
            {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)}/>}
          </CardWrapper>
        )}
      </div>
    </div>
  )
}

export default AgentsPage;
