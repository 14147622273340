import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { patchUserUnblock } from "../../../api/services/user/requests";

import { EUserRole, IUser } from "../../../common/types/dto/user";

import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import Input from "../../../common/components/Input/Input";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";
import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";
import Button from "../../../common/components/Button/Button";

import BanUserModal from "../../../common/components/modals/BanUserModal";

const UserProfile = ({ user }: { user: IUser }) => {
  const { t } = useTranslation();

  const { setIsOpen, setChildren } = useGlobalModalStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
  }

  const {
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const handleBlockClient = async () => {
    if (!user?.id || user.role === EUserRole.Admin) return;
    setChildren(<BanUserModal id={user.id} />);
    setIsOpen(true);
  }

  const handleUnblockClient = async () => {
    if (!user?.id) return;

    try {
      setLoader(true);
      await patchUserUnblock(user.id)
      location?.reload();
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <CardWrapper className="p-[25px] min-h-[calc(100vh-180px)]">
      {user?.isBlocked && (
        <div className="rounded-[10px] bg-[#E53E3E1A] text-[15px] text-[#5D5D5D] p-[10px] mb-[36px]">{t('profile_is_blocked')}</div>
      )}
      <div className="grid grid-cols-[400px_400px] gap-[24px]">
        <Input
          label={t('full_name')}
          name="name"
          control={control}
          disabled
        />
        <PhoneInput
          label={t('phone_number')}
          name="phone"
          control={control}
          defaultCountry="CZ"
          disabled
        />
        <Input
          label={t('email')}
          name="email"
          control={control}
          disabled
        />
      </div>
      {user?.isBlocked ? <Button className="font-bold mt-[36px]" onClick={handleUnblockClient}>{t('unblock_client')}</Button> : <Button className="font-bold mt-[36px]" color="red" onClick={handleBlockClient}>{t('block_client')}</Button>}
    </CardWrapper>
  )
}

export default UserProfile;
