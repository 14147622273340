import React from "react";
import {Link, Outlet} from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Modal from "../../common/components/Modal/Modal";
import Container from "../../common/components/Container/Container";

import Menu from "../../features/menu/components/Menu";
import Header from "../../features/header/components/Header";

import Logo from "../../common/assets/images/Logo";
import MobileVersionImage from "../../common/assets/images/MobileVersionImage";

const BaseLayout = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="block min-700:hidden h-screen">
        <div className="pb-[70px]">
          <div className="fixed w-full bg-primary z-[1000]">
            <Container>
              <div className="flex items-center h-[70px]">
                <Logo width={97} height={33} color="white"/>
              </div>
            </Container>
          </div>
        </div>
        <div className="h-[calc(100vh-70px)]">
          <Container>
            <div className="h-full flex flex-col justify-between gap-[25px] md:gap-[50px] py-[25px] md:py-[50px]">
              <div>
                <div className="md:flex-center mb-[40px]">
                  <MobileVersionImage/>
                </div>
                <div className="md:flex-center flex-col">
                  <h5 className="text-heading-5 text-heading mb-[10px]">{t('coming_soon')}</h5>
                  <p className="text-body-default text-body">{t('coming_soon_description')}</p>
                </div>
              </div>
              <div>
                <div className="w-[260px] md:w-full text-[#9C9AA5] text-[12px] text-center mx-auto">
                  {t('login_text_accept')}
                  {' '}
                  <Link to={`/documents`}>
                    <span className="text-[#26203B] text-[12px]">
                      {t('link_terms')}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="h-dvh hidden min-700:grid grid-cols-[250px_auto] grid-rows-[80px_auto]">
        <div className="row-span-2">
          <Menu/>
        </div>
        <div>
          <Header/>
        </div>
        <main className="bg-[#f7f7f7]">
          <Outlet/>
        </main>
        <Modal/>
      </div>
    </>
  );
}

export default BaseLayout;
