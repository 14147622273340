import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { postAuthEmailConfirm } from "../../api/services/auth/requests";

import useGlobalLoaderStore from "../stores/useGlobalLoaderStore";
import useAuthStore from "../stores/useAuthStore";
import useGlobalErrorStore from "../stores/useGlobalErrorStore";
import useGlobalModalStore from "../stores/useGlobalModalStore";

import useCreateAccountStore from "../../features/create-account/stores/useCreateAccountStore";

const useConfirmEmailPin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError, setPinError } = useGlobalErrorStore();
  const { setAccessToken, setRefreshToken } = useAuthStore();
  const { setIsOpen } = useGlobalModalStore();

  const { base: { email }, resetStore } = useCreateAccountStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      pin: ""
    }
  });

  const pin = watch("pin");

  const onSubmit = async ({pin}: { pin: string }) => {
    try {
      setLoader(true);
      const {accessToken, refreshToken} = await postAuthEmailConfirm({otp: pin, email});
      setIsOpen(false);
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      resetStore();
      window.location.href = "/dashboard";
    }
    catch (error: any) {
      setPinError(true);
      setError(error?.response?.data?.message || t('request_failed'))
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (pin.length < 4) {
      setPinError(false);
    }
  }, [pin]);

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);

  return {
    control,
  }
}

export default useConfirmEmailPin;
