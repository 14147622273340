import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { patchUserUnblock } from "../../../api/services/user/requests";

import { EUserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import LangSwitcher from "../../../common/components/LangSwitcher/LangSwitcher";
import Button from "../../../common/components/Button/Button";

import BanUserModal from "../../../common/components/modals/BanUserModal";

import MenuItems from "./MenuItems";
import MenuDocumentationBlock from "./MenuDocumentationBlock";

import Logo from "../../../common/assets/images/Logo";

const Menu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, impersonationToken, setImpersonationToken } = useAuthStore();
  const { setChildren, setIsOpen } = useGlobalModalStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const handleUnImpersonate = () => {
    setImpersonationToken(null);
    navigate("/");
    location?.reload();
  }

  const handleBlockAgent = async () => {
    if (!user?.id || user.role === EUserRole.Admin) return;
    setChildren(<BanUserModal id={user.id} />);
    setIsOpen(true);
  }

  const handleUnblockAgent = async () => {
    if (!user?.id) return;

    try {
      setLoader(true);
      await patchUserUnblock(user.id)
      location?.reload();
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div className="h-full w-full pl-[20px] pr-[42px] py-[24px]">
      <Link to="/" className="flex-center">
        <Logo width={140} height={49}/>
      </Link>
      <div className="mt-[28px]">
        <MenuItems/>
      </div>
      {(user?.role === EUserRole.Agent || !!impersonationToken) && (
        <>
          <div className="text-white my-[28px]">
            <MenuDocumentationBlock />
          </div>
          <Link to="/documents">
            <div className="text-[10px] text-[#26203B] text-center">{t('link_terms')}</div>
          </Link>
        </>
      )}
      {!!impersonationToken && (
        <div className="flex flex-col gap-[20px] mt-[28px]">
          <button className="text-[16px] text-[#006CFB]" onClick={handleUnImpersonate}>{t('back_to_admin')}</button>
          {user?.isBlocked ? <Button className="font-bold" onClick={handleUnblockAgent}>{t('unblock_agent')}</Button> : <Button className="font-bold" color="red" onClick={handleBlockAgent}>{t('block_agent')}</Button>}
        </div>
      )}
      <div className="mt-7 mb-[90px]">
        <LangSwitcher />
      </div>
    </div>
  );
}

export default Menu;
