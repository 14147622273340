import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LangSwitcher from "../../common/components/LangSwitcher/LangSwitcher";
import Button from "../../common/components/Button/ButtonSite";

import LoginForm from "../../features/login/components/LoginForm";

import Logo from "../../common/assets/images/Logo";
import SignInIcon from "../../common/assets/icons/SignInIcon";
import ArrowRight from "../../common/assets/icons/ArrowRight";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const handleRegisterRedirect = () => {
    navigate('/create-account');
  };
  
  const handleBackClick = () => {
    window.location.href = 'https://reelty.cz';
  };
  
  return (
    <div className="flex flex-1 flex-col justify-between items-center gap-[80px]">
      {/* Added back button with white background */}
      <button
        className="absolute top-14 left-14 flex-center w-[42px] h-[42px]  z-10"
        onClick={handleBackClick}
      >
        <ArrowRight className="text-heading transform rotate-180"/>
      </button>
      
      <div className="block">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      
      <div className="w-full flex-center">
        <div className="w-full sm:w-[420px]">
          <div className="flex items-center gap-[12px] mb-[30px]">
            <SignInIcon/>
            <div className="text-heading-3 text-heading">{t('sign_in')}</div>
          </div>
          <div className="mb-[30px]">
            <LoginForm/>
          </div>
          <div className="text-center mb-[30px]">{t('or')}</div>
          <div>
            <Button
              onClick={handleRegisterRedirect}
              variant="primary-outlined"
              fullWidth
            >
              {t('create_account')}
              <div>
                <ArrowRight/>
              </div>
            </Button>
          </div>
        </div>
      </div>
      
      <div>
        <div className="mb-[30px]">
          <LangSwitcher/>
        </div>
        <div className="max-w-[260px] text-[#9C9AA5] text-[12px] text-center font-semibold">
          {t('login_text_accept')}{' '}
          <Link to="/documents">
            <span className="text-heading text-[12px] text-center font-semibold">{t('link_terms')}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
