import { useTranslation } from "react-i18next";

import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";

import Input from "../../../common/components/Input/InputSite";
import Button from "../../../common/components/Button/ButtonSite";

import useFormLogin from "../hooks/useFormLogin";

import ForgotPasswordEmailModal from "./modals/ForgotPasswordEmailModal";

const LoginForm = () => {
  const { t } = useTranslation();

  const { setChildren, setIsOpen } = useGlobalModalStore();

  const {
    control,
    handleSubmit,
    isValid,
  } = useFormLogin();

  const handleForgotPassword = () => {
    setChildren(<ForgotPasswordEmailModal />);
    setIsOpen(true);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-[30px]">
        <Input
          label={t("email_label")}
          name="email"
          control={control}
          placeholder={t("email_placeholder")}
        />
        <Input
          type="password"
          label={(
            <div className="flex justify-between items-center">
              <div>{t("password_label")}</div>
              <button onClick={handleForgotPassword} type="button" className="text-[12px] text-[#9c9aa5] underline">
                {t("forgot_password")}
              </button>
            </div>
          )}
          name="password"
          control={control}
          showErrors={false}
          placeholder={t("password_placeholder")}
        />
        <div>
          <Button
            type="submit"
            disabled={!isValid}
            withCooldown
            fullWidth
          >
            {t("sign_in")}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
