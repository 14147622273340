import React from "react";
import { Navigate, RouteObject } from "react-router";

import AuthLayout from "../pages/auth/AuthLayout";
import LoginPage from "../pages/auth/LoginPage";
import CreateAccountPage from "../pages/auth/CreateAccountPage";

import DocumentsPage from "../pages/Documents";
import NotFoundPage from "../pages/NotFoundPage";

const routesUnauthorized: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <LoginPage/>,
      },
      {
        path: "/create-account",
        element: <CreateAccountPage/>,
      },
      {
        path: "/documents",
        element: <DocumentsPage/>,
      },
      {
        path: "/dashboard",
        element: <Navigate to="/" />,
      },
      {
        path: "*",
        element: <NotFoundPage/>
      }
    ]
  },
]

export default routesUnauthorized;
